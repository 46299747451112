import React from 'react'
import {getCountryVatRegex} from 'helpers/location'
import useLocalization from 'hooks/useLocalization'
import FormInput from './FormInput'

const FormVatCodeInput = ({name, large, disabled}: {name: string; large?: boolean; disabled?: boolean}) => {
  const {translate} = useLocalization()

  return (
    <FormInput
      required
      validate={(value, formValues) => {
        const formData = formValues()

        const countryCode = formData?.['location.countryCode']

        if (!getCountryVatRegex(countryCode).test(value) || !countryCode) {
          return translate('Must be a valid VAT number')
        }

        return true
      }}
      large={large}
      label={translate('Company vat. number')}
      name={name}
      data-cy={name}
      disabled={disabled}
    />
  )
}

export default FormVatCodeInput
